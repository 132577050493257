<template>
  <div>
    <v-card class="ma-3">
      <g-overlay :is-relay="isOverlay"/>
      <page-progress :is-loading="isLoading"/>
      <page-title icon="mdi-file-replace-outline" title="Live Link Replacement" :show-button="true"
                  btn-link="/sale-counters"/>
      <v-container class="pt-0" fluid>
        <v-row>
          <v-col cols="12" md="5" sm="12" xl="5" xs="12">
            <v-card>
              <v-card-text>
                <v-row dense no-gutters>
                  <v-col cols="12">
                    <v-form ref="detailFormRef" v-model="isReplacementValid" lazy-validation>
                      <v-autocomplete
                          v-model="detail.replacedSite"
                          :rules="requiredFieldRule"
                          :items="details"
                          item-text="site.site_url"
                          item-value="id"
                          outlined
                          dense
                          chips
                          small-chips
                          label="Select a Site"
                          clearable
                          return-object
                          @change="detailHandler"
                      >
                        <template v-slot:item="{ index, item }">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title v-html="item.site.site_url"></v-list-item-title>
                              <v-list-item-subtitle v-html="item.liveLink"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="7" sm="12" xl="7" xs="12">
            <v-card>
              <v-card-text>
                <v-form ref="cartFormRef" v-model="formValid" lazy-validation>
                  <v-row dense no-gutters>
                    <v-col cols="12" md="12">
                      <g-p-auto-complete
                          :dense="true"
                          :outlined="true"
                          :search-value="product.site"
                          :status=true
                          :validation="false"
                          apiPath="sites"
                          item-text="site_url"
                          search-label="Select a Site"
                          sort-by="site_url"
                          @clearSearchResult="handleClearSearchResult"
                          @selectSearchResult="onSelections"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                          v-model="product.liveLink"
                          label="Replaced Live Link"
                          :rules="requiredFieldRule"
                          outlined
                          dense
                          rows="2"
                          @input="checkLiveLinks()"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <v-card-actions>
                  <v-spacer/>
                  <add-button
                      :disabled="!detail.detailId || !product.liveLink"
                      text="Replace Live Link"
                      @onButtonClick="replaceLiveLinkHandler"
                  />
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12" xl="12" xs="12">
            <v-card>
              <v-card-text>
                <v-checkbox v-model="isReplaceBulkLink" label="Replace Bulk Live Link"/>
                <v-row v-if="isReplaceBulkLink">
                  <v-col cols="12" md="6">
                    <v-textarea v-model="liveLinks" rows="8" outlined/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-textarea
                        v-model="replacedLinks"
                        rows="8"
                        outlined
                        @change="setReplacedLinks($event)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12" xl="12" xs="12">
            <v-card>
              <v-data-table
                  :headers="headers"
                  :items="carts"
                  hide-default-footer
                  :disable-pagination="true"
              >
                <template v-slot:footer>
                  <v-divider/>
                  <v-toolbar flat>
                    <v-spacer/>
                    <cancel-button @onButtonClick="onCancelReplacement"/>
                    <confirm-button
                        text="Confirm"
                        :disabled="!carts.length"
                        @onButtonClick="onConfirmHandler"
                    />
                  </v-toolbar>
                </template>
                <template v-slot:top>
                  <v-toolbar dense flat>
                    Carts
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Cart is empty!
                </template>
                <template v-slot:item.actions={item}>
                  <v-btn
                      color="error"
                      icon
                      @click="deleteHandler(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <confirm-popup
        :show="showDeleteConfirm"
        confirm-event="onConfirmDelete"
        @closePopupHandler="showDeleteConfirm = false"
        @onConfirmDelete="deleteHandler(currentItem, true)"
    >
      <div>
        <span>Are you sure to delete this item?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showReplacementConfirm"
        confirm-event="onConfirmOrder"
        @closePopupHandler="showReplacementConfirm = false"
        @onConfirmOrder="onConfirmHandler(true)"
    >
      <div>
        <span>Are you sure to confirm to submit replacement?
       </span>
      </div>
    </confirm-popup>
  </div>
</template>

<script>
import GOverlay from "../components/GOverlay";
import PageProgress from "../components/PageProgress";
import PageTitle from "../components/PageTitle";
import SalesCounter from "../services/SalesCounter";
import GPAutoComplete from "../components/GPAutoComplete";
import AddButton from "../components/buttons/AddButton";
import Validation from "../components/mixins/Validation";
import CancelButton from "../components/buttons/CancelButton";
import ConfirmButton from "../components/buttons/ConfirmButton";
import _ from 'lodash';
import ConfirmPopup from "../components/popups/ConfirmPopup";
import Sites from "../services/Sites";

export default {
  name: "LiveLinkReplacement",
  components: {
    ConfirmPopup,
    ConfirmButton,
    CancelButton,
    AddButton,
    GPAutoComplete,
    PageTitle,
    PageProgress,
    GOverlay
  },
  mixins: [Validation],
  data() {
    return {
      isOverlay: false,
      isLoading: false,
      isReplacementValid: true,
      showDeleteConfirm: false,
      showReplacementConfirm: false,
      formValid: true,
      details: [],
      detail: {
        replacedSite: '',
        detailId: '',
        replaceSiteUrl: '',
        replaceSiteId: '',
        replaceLiveLink: ''
      },
      product: {
        site: '',
        site_url: '',
        siteId: '',
        liveLink: ''
      },
      carts: [],
      headers: [
        {
          text: 'Site URL',
          value: 'replaceSiteUrl'
        },
        {
          text: 'Live Link',
          value: 'replaceLiveLink'
        },
        {
          text: 'Replaced Site URL',
          value: 'site_url'
        },
        {
          text: 'Replaced Live Link',
          value: 'liveLink'
        },
        {
          text: 'Actions', value: 'actions', sortable: false
        }
      ],
      currentItem: {},
      salesCounterId: this.$route.params.id,
      isReplaceBulkLink: false,
      replacedLinks: ''
    }
  },
  mounted() {
    this.getSalesCounterDetails();
  },
  computed: {
    liveLinks() {
      if (this.details.length > 0) {
        let liveLinks = [];
        this.details.forEach((data) => {
          liveLinks.push(data.liveLink);
        })
        return liveLinks.join('\n');
      }
      return '';
    }
  },
  methods: {
    getSalesCounterDetails() {
      const id = this.salesCounterId;
      if (!id) return;
      this.isOverlay = true;
      SalesCounter.counterDetails({id})
          .then(({data: {data}}) => {
            this.details = data.items;

            this.isOverlay = false;
          })
          .catch((errors) => {
            this.isOverlay = false;
            this.$router.push({path: '/sale-counters'})
          })
    },
    async checkLiveLinks() {
      let site_url = this.product.site_url;
      const linkArr = this.product.liveLink ? this.product.liveLink.split("/") : '';

      if (linkArr.length < 2) return;

      const linkSite = linkArr[2] ? linkArr[2] : '';

      if (!site_url) {
        this.isOverlay = true;
        const site = await this.getBySiteUrl(linkSite);

        if (!site) {
          this.checkLiveLinkMessageHandler(site_url, linkSite);
          this.isOverlay = false;
          return;
        }
        site_url = site.site_url;
        this.product.site_url = site_url;
        this.product.site = {...site, site_url, siteId: site.id};

        await this.onSelections(this.product.site);
      }

      if (linkSite !== site_url) {
        this.product.liveLink = '';
        this.setSnackBar({
          text: `You are selected this ${site_url} and your live link from ${linkSite} does not matched each other. Please check your live link`,
          type: 'error'
        })
      }

      this.$nextTick(() => {
        this.product = {...this.product};
        this.isOverlay = false;
      });
    },
    handleClearSearchResult() {
      this.product = {
        ...this.product,
        site: '',
        site_url: '',
        siteId: '',
      }
    },
    resetProductHandler() {
      this.product = {
        site: '',
        site_url: '',
        siteId: '',
        liveLink: ''
      }
    },
    onSelections(selection) {
      const {site_url, id: siteId} = selection;

      this.product = {
        ...this.product,
        site_url,
        siteId
      }

      this.checkLiveLinks();
    },
    detailHandler(selection) {
      if (!selection) {
        this.resetDetails();
      } else {
        const {id: detailId, site, siteId: replaceSiteId, liveLink: replaceLiveLink} = selection;

        this.detail = {
          ...this.detail,
          detailId,
          replaceSiteUrl: site.site_url,
          replaceSiteId,
          replaceLiveLink
        }
      }
    },
    replaceLiveLinkHandler() {
      if (!this.$refs.cartFormRef.validate()) return;
      const index = this.carts.findIndex(data => data.detailId === this.detail.detailId);
      if (index > -1) {
        this.carts[index] = {...this.detail, ...this.product};
      } else {
        this.carts.push({...this.detail, id: this.detail.detailId, ...this.product})
      }
      this.$nextTick(() => {
        this.carts = _.cloneDeep(this.carts);
      })
      this.$refs.cartFormRef.reset();
      this.$refs.detailFormRef.reset();
      this.resetDetails();
      this.resetProductHandler();
    },
    resetDetails() {
      this.detail = {
        replacedSite: '',
        detailId: '',
        replaceSiteUrl: '',
        replaceSiteId: '',
        replaceLiveLink: ''
      }
    },
    deleteHandler(item, confirm = false) {
      if (!confirm) {
        this.currentItem = item;
        this.showDeleteConfirm = true;
        return;
      }

      const id = this.currentItem.id;
      const index = this.carts.findIndex((item) => item.id === id);

      if (index > -1) {
        this.carts.splice(index, 1);
      }

      this.showDeleteConfirm = false;
      this.currentItem = null;
    },
    onCancelReplacement() {
      this.$refs.cartFormRef.reset();
      this.$refs.detailFormRef.reset();
      this.carts = [];
      this.resetDetails();
      this.resetProductHandler();
    },
    onConfirmHandler(confirm = false) {
      if (!confirm) {
        this.showReplacementConfirm = true;
        return;
      }
      this.showReplacementConfirm = false;

      SalesCounter.replacement({salesCounterId: this.salesCounterId, carts: this.carts})
          .then((response) => {
            this.setSnackBar({text: response.data.message, type: 'info'});
            this.$router.push({path: '/sale-counters'})
          })
          .catch((errors) => {
            this.setSnackBar({text: 'Something wrong, please check', type: 'error'})
          })

    },
    setSnackBar({text, type}) {
      this.$store.commit('SET_SNACKBAR', {text, type});
    },
    checkLiveLinkMessageHandler(site_url, linkSite) {
      this.product.liveLink = '';
      this.setSnackBar({
        text: `You are selected this ${site_url} and your live link from ${linkSite} does not matched each other. Please check your live link`,
        type: 'error'
      });
    },
    async getBySiteUrl(url) {
      return Sites
          .getByOne({site_url: url})
          .then((response) => response.data.data.item);
    },
    async setReplacedLinks(event) {
      if (!event) return;
      this.isOverlay = true;
      let links = event.split("\n");
      let sites = links.map((item) => {
        let siteLink = this.getSiteUrl(item);
        if (siteLink) {
          return siteLink
        }
      });

      sites = _.uniq(sites);

      const {data: {data: {items}}} = await Sites.getBulkSite({sites: sites});

      links.forEach((item, key) => {
        let siteLink = this.getSiteUrl(item);
        let indexOfSite = items.findIndex((data) => data.site_url === siteLink);

        if (indexOfSite > -1) {
          let prevDetails = this.details[key];
          let presentDetails = items[indexOfSite];
          let detail = {
            detailId: prevDetails.id,
            replaceSiteUrl: prevDetails.site.site_url,
            replaceSiteId: prevDetails.siteId,
            replaceLiveLink: prevDetails.liveLink
          }
          let product = {
            site_url: presentDetails.site_url,
            siteId: presentDetails.id,
            liveLink: item
          }

          const index = this.carts.findIndex(data => data.detailId === detail.detailId);
          if (index > -1) {
            this.carts[index] = {...detail, ...product};
          } else {
            this.carts.push({...detail, id: detail.detailId, ...product})
          }
          this.$nextTick(() => {
            this.carts = _.cloneDeep(this.carts);
          })
        }
      });
      this.replacedLinks = '';
      this.isOverlay = false;
    },
    getSiteUrl(item) {
      const linkArr = item.split("/");
      if (linkArr.length > 2) {
        return linkArr[2] ? linkArr[2] : '';
      }
      return '';
    }

  }
}
</script>

<style scoped>

</style>